import {ACTION_UPDATE_DATA} from './constants';
import ContentfulLoader from ".";

const location = require('../customTypes/location');
const instructor = require('../customTypes/instructor');
const newsType = require('../customTypes/news');
const eventType = require('../customTypes/event');

const initialState = {
  data: new ContentfulLoader([location, instructor, newsType, eventType]).generateState()
}

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ACTION_UPDATE_DATA:
      return { data };
    default:
      return state;
  }
};