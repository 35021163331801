const { readField, readMarkdown, readImage } = require('../contentful/fields')
const common = require('../contentful/types/common')

const instructor = (field, loc = 'en-AU') => ({
  ...common(field, null, field.fields.name, loc),
  name: readField(field.fields.name, loc),
  grade: readField(field.fields.grade, loc),
  information: readMarkdown(field.fields.information, loc),
  achievements: readMarkdown(field.fields.achievements, loc),
  contactDetails: readMarkdown(field.fields.contactDetails, loc),
  image: readImage(field.fields.image, loc, field.sys.space.sys.id),
})

module.exports = instructor
module.exports.type = 'instructor'

module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'instructor') {
    return instructor(subField, loc)
  }
  return null
}
module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'instructor'
  )

  const processedLocations = locations.map(l => instructor(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
    result[l.slug] = l
  })
  result.all = processedLocations

  return result
}
