const {
  readField,
  readMarkdown,
  loadSubId,
  readImage,
} = require('../contentful/fields')
const common = require('../contentful/types/common')

const location = (field, loc = 'en-AU') => ({
  ...common(field, null, field.fields.title, loc),
  title: readField(field.fields.title, loc),
  region: readField(field.fields.region, loc),
  address: readMarkdown(field.fields.address, loc),
  location: readField(field.fields.location, loc),
  contact: readField(field.fields.contact, loc),
  information: readMarkdown(field.fields.information, loc),
  instructors: loadSubId(
    (field.fields.instructors && field.fields.instructors[loc]) || [],
    loc
  ),
  image: readImage(field.fields.image && field.fields.image[loc], loc, field.sys.space.sys.id),
})

module.exports = location
module.exports.type = 'location'
module.exports.tryLoad = (subField, loc = 'en-AU') => {
  if (subField.sys.contentType.sys.id === 'location') {
    return location(subField, loc)
  }
  return null
}
module.exports.load = (data, loc = 'en-AU') => {
  const locations = data.entries.filter(
    e => e.sys.contentType.sys.id === 'location'
  )
  const processedLocations = locations.map(l => location(l, loc))
  const result = {}
  processedLocations.forEach(l => {
    result[l.id] = l
    result[l.title] = l
    result[l.slug] = l
  })
  result.all = processedLocations

  return result
}
