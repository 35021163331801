import Contentful from '../data/contentful'
const location = require('../data/customTypes/location')
const instructor = require('../data/customTypes/instructor')
const newsType = require('../data/customTypes/news')
const eventType = require('../data/customTypes/event')

const initialState = {
  isDrawerOpen: false,
  contentful: new Contentful([
    location,
    instructor,
    newsType,
    eventType,
  ]),
}

const TOOGLE_DRAWER = 'TOGGLE_DRAWER'
export const toggleDrawer = open => ({ type: TOOGLE_DRAWER, payload: open })

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case TOOGLE_DRAWER:
      return { ...state, isDrawerOpen: payload }
    default:
      return state
  }
}
