const ContentfulProd = {
  space: 'k19quh0rreko',
  accessToken:
    '57050280b982ccd5399031701ec9a9f19b89048b1ddbb6a4ae1db0680ad8091e',
}

const ContentfulPreview = {
  host: 'preview.contentful.com',
  space: 'k19quh0rreko',
  accessToken:
    '8903a5c604968c7ad95b5848c87748ff90539d7ecb5cf72c0cce25dc2fee7527',
}

const AllowReload = true

module.exports = {
  ContentfulProd,
  ContentfulPreview,
  AllowReload,
}
