const Contentful = require('contentful')

const data = require('./data.json')
const {
  ContentfulProd,
  ContentfulPreview,
  AllowReload,
} = require('../../utils/config')

const ls = (key) => {
  if (typeof window !== `undefined`) {
    return window ? JSON.parse(window.localStorage.getItem(key)) : false
  }
  return false
}


const getParameterByName = (name, url) => {
  let localUrl = url
  /* eslint-disable */
  if (!url) localUrl = window.location.href
  /* eslint-enabled */
  const localName = name.replace(/[\[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${localName}(=([^&#]*)|&|#|$)`)

  const results = regex.exec(localUrl)
  if (!results) return null
  return results[2]
}

class ContentfulContent {
  constructor(customTypes) {
    this.data = data
    this.customTypes = customTypes || []
  }

  loadPreview() {
    this.client = Contentful.createClient(ContentfulPreview)
    return this.client.sync({ initial: true })
  }

  loadProd() {
    this.client = Contentful.createClient(ContentfulProd)
    return this.client.sync({ initial: true })
  }

  refreshData() {
    if (global.window && global.window.location) {
      /* console.log(
        `LOADING PREVIEW: ${getParameterByName('preview')}` === 'true'
      ) */
      if (ls('showCMSTags') || getParameterByName('preview') === 'true') {
        return this.loadPreview() // .then(newData => this.generateState(newData))
      }
      if (AllowReload) {
        return this.loadProd() //.then(newData => this.generateState(newData))
      }
    }
    return Promise.resolve(this.data)
  }

  generateState(newData) {
    this.data = { ...this.data, ...newData }
    const types = [
      'layout',
      'page',
      'product',
      'footer',
      'topBar',
      'element',
      'asset',
      'simpleCollection',
      'blog',
      'photoGallery',
    ]
    const result = {}
    types.forEach(type => {
      result[`${type}s`] = require(`./types/${type}`).load(this.data)
    })
    this.customTypes.forEach(type => {
      result[type.type] = type.load(this.data)
    })
    return result
  }

  get(type, loc) {
    if (typeof type === 'string')
      return require(`./types/${type}`).load(this.data, loc)
    else return type.load(this.data, loc)
  }
}
module.exports = ContentfulContent
