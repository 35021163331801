import axios from 'axios'
// import { GAEvent } from '@ic-hosting/ich-react-redux-ga'

export const OnSubmitForm = (result, confirmation) => ({
  type: 'CONTACT_FORM_SUBMITTED',
  result,
  confirmation,
})

export const LoadData = payload => ({
  type: 'CONTACT_FORM_LOAD_DATA',
  payload,
})

const exportFormData = state =>
  state.form.contactForm && state.form.contactForm.values

export const SubmitForm = (contactFormApiUrl) => (dispatch, getState) => {
  dispatch({ type: 'CONTACT_FORM_REQUEST_STARTED' })
  const state = getState()

  const data = exportFormData(state)
  // dispatch(
  //   GAEvent({
  //     category: 'Contact',
  //     action: 'Submit',
  //   })
  // )
  const message = Object.keys(data)
    .map(key => {
      if (key !== 'captcharesponse') {
        return `${key}: ${data[key]}`
      }
      return ''
    })
    .join('\n')
  return axios
    .post(contactFormApiUrl, {
      message,
      subject: 'Rhee Taekwondo - Contact Form',
      captcha: data.captcharesponse,
    })
    .then(response => {
      dispatch({
        type: 'CONTACT_FORM_SUBMITTED',
        data: response,
      })
      console.log(response)
    })
    .catch(err => {
      debugger
      dispatch({
        type: 'CONTACT_FORM_FAILED',
        data: 'Error Submitting form',
      })
      // an error occurred
    })
  // work out how to send emails directly from client side if possible.
  // Initialize the Amazon Cognito credentials provider
  /* const infra = new InfrastructureConfig().getOutputs()
  AWS.config.region = infra.region // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: infra.cognitoPoolId,
  })
  // AWS.config.credentials.get(() => {
  const sns = new AWS.SNS()
  const params = {
    Message: JSON.stringify(data),
    Subject: 'Rhee Taekwondo - Contact Form',
    TopicArn: infra.contact_form_sns_arn,
  }
  return new Promise((resolve, reject) =>
    sns.publish(params, (err, pdata) => {
      if (err) {
        dispatch({
          type: 'CONTACT_FORM_FAILED',
          data: err,
        })
        reject(err) // an error occurred
      } else {
        dispatch({
          type: 'CONTACT_FORM_SUBMITTED',
          data: pdata,
        })
        resolve({ pdata }) // successful response
      }
    })
  ) */
}

export const reducer = (state = {}, action) => {
  const { type } = action
  switch (type) {
    case 'CONTACT_FORM_SUBMITTED':
      return {
        ...state,
        confirmation: {
          ...action.data,
        },
        submitting: false,
        submitted: true,
      }
    case 'CONTACT_FORM_FAILED':
      return {
        ...state,
        error: action.data,
        submitting: false,
        submitted: false,
      }
    case 'CONTACT_FORM_REQUEST_STARTED':
      return {
        ...state,
        submitting: true,
        submitted: false,
      }
    case 'CONTACT_FORM_LOAD_DATA':
      return {
        ...state,
        initialState: action.payload,
        submitting: false,
        submitted: false,
      }
    default:
      return state
  }
}

export default { SubmitForm, OnSubmitForm }
